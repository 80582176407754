<template>
  <div class="anchor-pop-edit-container">
    <a-modal
      title="编辑信息"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="500px"
    >

      <div class="list">
        <div class="list-left">
          <div class="label">
            <div class="txt">备注</div>
            <div class="hao">：</div>
          </div>
          <div class="value l-height">
            <a-input v-if="isNote" v-model="notes" placeholder="请输入备注" style="width:180px" :maxLength="20" />
            <div v-else>{{notes | kong}}</div>
            <le-button txt="确认"  width="50px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="comNotes()" v-if="isNote"  />
            <le-button txt="修改"  width="50px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="isNote=true" v-else />
          </div>
        </div>
      </div>

      <div class="list">
        <div class="list-left">
          <div class="label">
            <div class="txt">联系方式</div>
            <div class="hao">：</div>
          </div>
          <div class="value l-height">
            <a-input v-if="isContact" v-model="phone" placeholder="请输入联系方式" style="width:180px" :maxLength="20" />
            <div v-else>{{phone | kong}}</div>
            <le-button txt="确认"  width="50px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="comContact()" v-if="isContact" />
            <le-button txt="修改"  width="50px" height="30px" fontSize="16px" style="margin-left:19px" @btnClick="isContact=true" v-else />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      confirmLoading: false,
      isNote:false,
      isContact:false,
      phone:'',
      notes:''
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row:{
      type:Object,
      default:()=>{
        return {}
        }
    }
  },
  computed: {},
  mounted() {
    console.log('eee',this.row)
    this.phone=this.row.phone
    this.notes=this.row.notes
  },
  methods: {
    handleOk(e) {
      if(this.isNote||this.isContact){
      this.$api.post(`v1/user/anchor/query_history_edit`,{id:this.row.id,phone:this.phone,notes:this.notes}).then(res=>{
        console.log(res)
        if(res.code==200){
          // this.isContact=false;
          this.$emit("handleOk");
        }else{
          this.$message.error(res.message)
        }
      })
      }else{
        this.$emit("handleOk");
      }
    },
    handleCancel(e) {
      this.$emit("handleCancel");
    },
    onChange(date, dateString) {
      console.log(date, dateString);
    },
    comContact(){
      this.$api.post(`v1/user/anchor/query_history_edit`,{id:this.row.id,phone:this.phone}).then(res=>{
        console.log(res)
        if(res.code==200){
          this.isContact=false;
        }else{
          this.$message.error(res.message)
        }
      })
    },
    comNotes(){
      this.$api.post(`v1/user/anchor/query_history_edit`,{id:this.row.id,notes:this.notes}).then(res=>{
        console.log(res)
        if(res.code==200){
          this.isNote=false;
        }else{
          this.$message.error(res.message)
        }
      })
    }

  },
};
</script>

<style lang="less" scoped>
.anchor-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
</style>
